// Generated by Framer (9f68555)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, getPropertyControls, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as sharedStyle from "../css/QPTmydKPK";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const serializationHash = "framer-PBbng"

const variantClassNames = {TUIPQSIeZ: "framer-v-d61g4f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableEnumMap = {Bold: "bold", Duotone: "duotone", Fill: "fill", Light: "light", Regular: "regular", Thin: "thin"}

const getProps = ({height, icon, iconColor, id, title, weight, width, ...props}) => { return {...props, fPdfDrTRv: humanReadableEnumMap[weight] ?? weight ?? props.fPdfDrTRv ?? "fill", hxelCmDBG: title ?? props.hxelCmDBG ?? "Benefits", nkyMT5Gn4: iconColor ?? props.nkyMT5Gn4 ?? "rgb(201, 128, 79)", UxnZrSyH8: icon ?? props.UxnZrSyH8 ?? "Star"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: string;iconColor?: string;weight?: keyof typeof humanReadableEnumMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, UxnZrSyH8, nkyMT5Gn4, fPdfDrTRv, hxelCmDBG, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "TUIPQSIeZ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-d61g4f", className, classNames)} data-framer-name={"Tag"} layoutDependency={layoutDependency} layoutId={"TUIPQSIeZ"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-4k92sv-container"} layoutDependency={layoutDependency} layoutId={"KaYDYmPDl-container"}><Phosphor color={nkyMT5Gn4} height={"100%"} iconSearch={"House"} iconSelection={UxnZrSyH8} id={"KaYDYmPDl"} layoutId={"KaYDYmPDl"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={fPdfDrTRv} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-jycf3n"} data-styles-preset={"QPTmydKPK"}>Benefits</motion.p></React.Fragment>} className={"framer-1b2b1yy"} data-framer-name={"Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"B43ci5_iI"} style={{"--framer-paragraph-spacing": "0px"}} text={hxelCmDBG} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PBbng.framer-b0o5o7, .framer-PBbng .framer-b0o5o7 { display: block; }", ".framer-PBbng.framer-d61g4f { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-PBbng .framer-4k92sv-container { flex: none; height: 22px; position: relative; width: 22px; }", ".framer-PBbng .framer-1b2b1yy { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-PBbng.framer-d61g4f { gap: 0px; } .framer-PBbng.framer-d61g4f > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-PBbng.framer-d61g4f > :first-child { margin-left: 0px; } .framer-PBbng.framer-d61g4f > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 93
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"UxnZrSyH8":"icon","nkyMT5Gn4":"iconColor","fPdfDrTRv":"weight","hxelCmDBG":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerd1chySEt1: React.ComponentType<Props> = withCSS(Component, css, "framer-PBbng") as typeof Component;
export default Framerd1chySEt1;

Framerd1chySEt1.displayName = "Helper/Tag";

Framerd1chySEt1.defaultProps = {height: 27, width: 93};

addPropertyControls(Framerd1chySEt1, {UxnZrSyH8: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Star", description: undefined, hidden: undefined, title: "Icon"}, nkyMT5Gn4: {defaultValue: "rgb(201, 128, 79)", title: "Icon Color", type: ControlType.Color}, fPdfDrTRv: PhosphorControls?.["weight"] && {...PhosphorControls["weight"], defaultValue: "fill", description: undefined, hidden: undefined, title: "Weight"}, hxelCmDBG: {defaultValue: "Benefits", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerd1chySEt1, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PhosphorFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})